import Text from '@deprecated-ui/common/Text';
import device from '@deprecated-ui/_style/device';
import Image from 'global/Image';
import styled from 'styled-components';
import { Wrapper as BaseWrapper } from '../styled';

const Wrapper = styled(BaseWrapper).attrs({
  align: 'center',
})``;

const SectionHeader = styled(Text).attrs({
  color: '#2d2d2d',
  family: 'bold',
  as: 'h1',
  mb: '0',
  height: 1,
  align: 'center',
})`
  font-size: 36px;

  @media ${device.laptop} {
    font-size: 6.5vh;
  }
`;

const SectionDescription = styled(Text).attrs({
  family: 'light',
  height: 1.5,
  pt: '15px',
  align: 'center',
})`
  font-size: 16px;
  margin: 20px 0;

  @media ${device.tablet} {
    font-size: 22px;
  }

  @media ${device.laptop} {
    margin: 5vh 0;
  }
`;

const Illustration = styled(Image)`
  display: none;
  margin: auto;
  width: 100%;
  max-width: 715px;

  @media ${device.tablet} {
    display: block;
  }
`;

const PhoneFrame = styled.div`
  height: 296px;
  margin: auto;
  overflow: hidden;
  width: 260px;

  img {
    width: 100%;
  }

  @media ${device.tablet} {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 20px;
  }
`;

const PortfolioFrame = styled(Image)`
  bottom: 0;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 225px;

  @media ${device.tablet} {
    right: 20px;
  }
`;

export {
  Wrapper,
  SectionHeader,
  SectionDescription,
  PhoneFrame,
  PortfolioFrame,
  Illustration,
};
