import Image from 'global/Image';
import { useState } from 'react';
import ReactVisibilitySensor from 'react-visibility-sensor';
import clsx from 'clsx';
import {
  Wrapper,
  SectionHeader,
  SectionDescription,
  PhoneFrame,
  PortfolioFrame,
  Illustration,
} from './styled';

function Learning() {
  const [viewed, setViewed] = useState(false);

  return (
    <ReactVisibilitySensor
      offset={{ top: 140, bottom: 140 }}
      partialVisibility
      onChange={(v) => setViewed((s) => s || v)}
    >
      <Wrapper>
        <SectionHeader>Belajar Dulu dengan Virtual Trading</SectionHeader>
        <SectionDescription>
          Kamu dapat berlatih dan menguji strategi investasi Anda tanpa resiko
          dengan uang virtual senilai 100 juta.
        </SectionDescription>
        <div style={{ position: 'relative', width: '100%' }}>
          <Illustration
            className={clsx('fadeInX', viewed && 'fadeIn')}
            src="/images/x7.svg"
            alt="Virtual trading illustration"
          />
          <PhoneFrame>
            <Image src="/images/iphone6.webp" alt="iPhone Frame" />
          </PhoneFrame>
          <PortfolioFrame src="/images/bag.webp" alt="Portfolio sample" />
        </div>
      </Wrapper>
    </ReactVisibilitySensor>
  );
}

export default Learning;
